


































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { getCustomerInfo , identifyCustomer , getUserInfo,listCustomerFile,downloadCustomerFlie,removeAttachments} from "@/api";
import {baseURL} from '../../api/httpRequest';
@Component
export default class Identify extends Vue {
  baseURL=baseURL;
  loading=false;
  pwCustomer = {
    id:"",
    companyType: "",
    businessField: "",
    companyName: "",
    position: "",
    profile: "",
    business: "",
    industry:"",
    fullName:"",
    status:"",
    fullNameBak:"",
    businessLicenseList: [],
    cardList: [],
    bankInfoList: [],
    hxwStatus:"",
    website:"",
    type:"",
    address:"",
    phone:"",
    email:"",
  };
  userInfo = {
    isVip : 0,
    companyId : 0,
    userId : 0,
    phonenumber:"",
    userType:"",
    
  };
  myHeaders={Authorization: window.localStorage.getItem('Authorization')}
  dialogCardVisible=false;
  customerFileList = [];
  mounted() {
    this.getUserInfo();
    
  }
getUserInfo(): void{
  getUserInfo({}).then(res => {
    if(res.status == 200){
        if(res.data.code == 200){
          this.userInfo = res.data.user
          window.localStorage.setItem("userInfo",JSON.stringify(res.data.user));
          this.getCustomerInfo();
        }else{
          this.$message.error(res.data.msg );
        }
      }else{
        this.$message.error(res.data.statusText);
      } 
    }).catch(() => {
      console.log(' error ')
    })
};
  getCustomerInfo(): void {
    let userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
    
    this.userInfo = userInfo;
    
    getCustomerInfo(userInfo.companyId)
      .then((res) => {
        // console.log(res);
        if (res.status == 200) {
          if (res.data.code == 200) {
            //  console.log(res.data.msg);
             this.pwCustomer = JSON.parse(res.data.msg);
             console.log(this.pwCustomer);
             this.pwCustomer['fullNameBak'] = this.pwCustomer.fullName; 
             if(this.pwCustomer.fullName == "嗨芯网待认证用户请不要审批通过"){
               this.pwCustomer.fullName = "";
             }
            //  if(this.pwCustomer.fullName == "待定"){
            //    this.pwCustomer.fullName = "";
            //  }
            // console.log(this.pwCustomer);
          } else {
            this.$message.error(res.data.msg);
          }
        } else {
          this.$message.error(res.data.statusText);
        }
      })
      .catch(() => {
        console.log(" error ");
      });
  };
removeAttachments(id:number,moduleId:number): void {
  

     // console.log(index, "==========", row);
    this.$confirm("删除后不可恢复,确定进行删除操作吗 ?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        ///////删除请求
          removeAttachments(id)
            .then((res) => {
              
              if (res.status == 200) {
                if (res.data.code == 200) {
                  this.$message.success("删除成功");
                  this.listCustomerFile(moduleId);
                  // this.userInfo = res.data.user
                  // window.localStorage.setItem("userInfo",JSON.stringify(res.data.user));
                } else {
                  this.$message.error(res.data.msg);
                }
              } else {
                this.$message.error(res.data.statusText);
              }
            })
            .catch(() => {
              console.log(" error ");
            });
      })
      ///////删除请求
      .catch(() => {console.log(" error ");});
    
    
  };
  handleDownload(id:number): void{
    console.log("dasd",id)
    downloadCustomerFlie(id).then(res => {
        const link=document.createElement('a');
        try{
          // let blob = new Blob([res.data],{type: 'application/vnd.ms-excel'});    //如果后台返回的不是blob对象类型，先定义成blob对象格式
          let blob =  res.data    //如果后台返回的直接是blob对象类型，直接获取数据
          let _fileName = decodeURIComponent(res.headers['content-disposition'].split(';')[1].split('=')[1]); //拆解获取文件名，
          link.style.display='none';
          
          // 方法1: 创建--下载--销毁
          // 兼容不同浏览器的URL对象
          const downloadurl  = window.URL || window.webkitURL ;
          link.href=downloadurl.createObjectURL(blob);
          link.download = _fileName;   //下载的文件名称
          link.click();
          // window.URL.revokeObjectURL(downloadurl);  //  #URL.revokeObjectURL()方法会释放一个通过URL.createObjectURL()创建的对象URL. 当你要已经用过了这个对象URL,然后要让浏览器知道这个URL已经不再需要指向对应的文件的时候,就需要调用这个方法.

          // //方法2:  创建--下载--销毁
          // // 兼容不同浏览器的URL对象
          // const url = window.URL || window.webkitURL || window.moxURL;
          // link.href=url.createObjectURL(blob);
          // link.setAttribute('download'_fileName.substring(_fileName.lastIndexOf('_')+1)));
          //   document.body.appendChild(link);
          //   link.click();
          //   document.body.removeChild(link);
          //   url.revokeObjectURL(link.href);//销毁url对象
        }catch (e) {
          console.log('下载的文件出错',e)
        }
      })

  }
  //附件列表
  listCustomerFile(moduleId:number): void {
  
    listCustomerFile(moduleId)
      .then((res) => {
        if (res.status == 200) {
          if (res.data.code == 200) {
            
            this.customerFileList = res.data.data;
            this.dialogCardVisible = true
            // for(let i = 0 , len = this.addressList.length ;i<len;i++){
            //   let item = this.addressList[i];
            
            // }
            // this.userInfo = res.data.user
            // window.localStorage.setItem("userInfo",JSON.stringify(res.data.user));
          } else {
            this.$message.error(res.data.msg);
          }
        } else {
          this.$message.error(res.data.statusText);
        }
      })
      .catch(() => {
        console.log(" error ");
      });
  }
  identifyCustomer():void{
    // if(this.pwCustomer.fullName == null || this.pwCustomer.fullName == ""){
    //   this.$message.error("公司名称不能为空");
    //   return;
    // }
    
    if(this.pwCustomer.type == "" || this.pwCustomer.type == null){
      this.$message.error("请选择认证性质");
      return;
    }
    let data ={
     // id:this.pwCustomer.fullNameBak == "嗨芯网待认证用户请不要审批通过" ? "":this.pwCustomer.id,
      id:this.pwCustomer.id,
      industry : this.pwCustomer.industry,
      business : this.pwCustomer.business,
      profile : this.pwCustomer.profile,
      // fullName : this.userInfo.phonenumber + "_" + this.pwCustomer.fullName,
      //fullName : this.userInfo.phonenumber + "_" + "嗨芯网待认证用户请不要审批通过",
      type: this.pwCustomer.type,
      website : this.pwCustomer.website,
      address : this.pwCustomer.address,
      email : this.pwCustomer.email,
      phone : this.pwCustomer.phone,
    }
    this.loading=true;
    identifyCustomer(data)
      .then((res) => {
        console.log(res);
        this.loading=false;
        if (res.status == 200) {
          if (res.data.code == 200) {
             console.log(res.data.msg);
            
            console.log(this.pwCustomer);
            this.$message.success("资料已提交成功,请耐心等待审核结果")
            this.getCustomerInfo();
            // window.location.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        } else {
          this.$message.error(res.data.statusText);
        }
      })
      .catch(() => {
        console.log(" error ");
      });
  };
  actionUrl(moduleId:number){
    //+'&name='+this.userInfo.userId + "_" + this.pwCustomer.fullName
    return baseURL+'offer/customer/uploadAttach?ajax=1&moduleId='+moduleId+'&id='+this.pwCustomer.id ;
  }
  handleRemove(file:any, businessLicenseList:any) {
    console.log(file, businessLicenseList);
  };
  handlePreview(file:any) {
    console.log(file);
  };
  handleExceed(files:any, businessLicenseList:any) {
    this.$message.warning(`当前限制选择 5 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + businessLicenseList.length} 个文件`);
  };
  beforeUpload(file:any){
      const isLtSize = file.size / 1024 / 1024 < 100;
      if (!isLtSize) {
        this.$message.error('上传图片大小不能超过 100MB!');
        return false;
      } 
      if(this.pwCustomer.fullName.indexOf("_") != -1){
        this.$message.error('公司名称不能包含下划线');
        return false;
      }
  };
  uploadSuccess(response:any, file :any , businessLicenseList :any) {
  console.log(response,"11");
  console.log(JSON.stringify(businessLicenseList))
    if(response.code == 200){
      this.$message.success("上传成功");
      // this.pwCustomer.id = response.data.id
    }else{
      this.$message.error("上传失败");
    }
  };
  uploadError(err : any, file :any, businessLicenseList :any) {
     console.log(err,"33",file,"44",businessLicenseList);
  };
}
